import type { JSX } from 'react';
import { FeatureText } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type FeaturesTextProps = SliceComponentProps<Content.FeatureTextSlice>;

const FeaturesTextSlice = ({ slice }: FeaturesTextProps): JSX.Element => {
  return <FeatureText {...slice} />;
};

export default FeaturesTextSlice;
